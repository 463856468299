<template xmlns="http://www.w3.org/1999/html" xmlns="http://www.w3.org/1999/html">
  <el-container style="text-align: left" class="main-container">
    <el-header style="text-align: left; height: 30px; margin-top: 20px">
      <div style="font-weight: bold; vertical-align: center">基本信息</div>
      <el-link
        class="return-button"
        type="default"
        size="mini"
        icon="el-icon-refresh-left"
        @click="toLoginPage"
      >
        离开
      </el-link>
    </el-header>
    <el-main style="text-align: left">
      <div style="width: 100%">
        <el-form
          style="vertical-align: center"
          size="small"
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          :label-position="labelPosition"
          :inline="true"
        >
          <el-form-item
            label="应聘岗位："
            prop="postId"
            style="position: relative"
            class="form-class2 first-label"
          >
            <el-select
              style="width: 200px"
              class="form-input"
              v-if="posts.length > 0"
              :disabled="isView"
              @change="changePost"
              v-model="ruleForm.postId"
            >
              <el-option
                v-for="(item, index) in posts"
                :key="index"
                :label="item.postName"
                :value="item.postId"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="姓名："
            prop="name"
            style="position: relative"
            class="form-class2 first-label"
          >
            <el-input
              :disabled="isView"
              class="form-input"
              v-model="ruleForm.name"
            >
            </el-input>
          </el-form-item>
          <el-form-item
            label="身份证："
            prop="idNo"
            style="position: relative"
            class="form-class2 first-label"
          >
            <el-input
              :disabled="isView"
              size="mini"
              class="form-input"
              v-model="ruleForm.idNo"
              @input="
                ruleForm.idNo = ruleForm.idNo.replace(/\D*(\d*[xX]?)$/i, '$1')
              "
              @blur="autoGetAge"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="性别："
            prop="gender"
            style="position: relative"
            class="form-class2 first-label"
          >
            <el-radio-group
              style="width: 200px"
              :disabled="isView"
              v-model="ruleForm.gender"
            >
              <el-radio
                v-for="(item, index) in sexArray"
                :label="item.id"
                :key="index"
                >{{ item.name }}</el-radio
              >
            </el-radio-group>
          </el-form-item>

          <el-form-item
            label="籍贯："
            prop="nativePlace"
            style="position: relative"
            class="form-class2 first-label"
          >
            <el-input
              :disabled="isView"
              class="form-input"
              v-model="ruleForm.nativePlace"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="民族："
            prop="nation"
            style="position: relative"
            class="form-class2 first-label"
          >
            <el-input
              :disabled="isView"
              class="form-input"
              v-model="ruleForm.nation"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="电话："
            prop="telNo"
            style="position: relative"
            class="form-class2 first-label"
          >
            <el-input
              :disabled="isView"
              class="form-input"
              v-model="ruleForm.telNo"
              @input="
                ruleForm.telNo = ruleForm.telNo.replace(
                  /^\D*([0-9]+)?.*$/g,
                  '$1'
                )
              "
            ></el-input>
          </el-form-item>
          <br />
          <el-form-item
            label="年龄："
            prop="age"
            style="position: relative"
            class="form-class2 first-label"
          >
            <el-input
              :disabled="isView"
              class="form-input"
              v-model="ruleForm.age"
              @input="
                ruleForm.age = ruleForm.age.replace(
                  /^\D*([0-9]+)?.*$/g,
                  '$1'
                )
              "
            ></el-input>
          </el-form-item>

          <el-form-item
            label="身高："
            prop="height"
            style="position: relative"
            class="form-class2 first-label"
          >
            <el-input
              :disabled="isView"
              class="form-input"
              v-model="ruleForm.height"
              @input="
                ruleForm.height = ruleForm.height.replace(
                  /^\D*(\d*(?:\.\d*)?).*$/g,
                  '$1'
                )
              "
            >
              <template slot="append">cm</template>
            </el-input>
          </el-form-item>
          <el-form-item
            label="体重："
            prop="weight"
            style="position: relative"
            class="form-class2 first-label"
          >
            <el-input
              :disabled="isView"
              class="form-input"
              v-model="ruleForm.weight"
              @input="
                ruleForm.weight = ruleForm.weight.replace(
                  /^\D*(\d*(?:\.\d*)?).*$/g,
                  '$1'
                )
              "
            >
              <template slot="append">kg</template>
            </el-input>
          </el-form-item>

          <el-form-item
            label="最高学历："
            prop="highestEducation"
            style="position: relative"
            class="form-class2 first-label"
          >
            <el-select
              style="width: 200px"
              v-model="ruleForm.highestEducation"
              placeholder="请选择"
              :disabled="isView"
            >
              <el-option label="统招本科" value="统招本科"></el-option>
              <el-option label="统招硕士" value="统招硕士"></el-option>
              <el-option label="大专" value="大专"></el-option>
              <el-option label="其他" value="其他"></el-option>
            </el-select>
            <!-- <el-input
            :disabled="isView"
            style="width: 220px"
            v-model="ruleForm.highestEducation"
          ></el-input> -->
          </el-form-item>
          <el-form-item
            label="最高学位："
            prop="highestDegree"
            style="position: relative"
            class="form-class2 first-label"
          >
            <el-select
              style="width: 200px"
              v-model="ruleForm.highestDegree"
              placeholder="请选择"
              :disabled="isView"
            >
              <el-option label="学士" value="学士"></el-option>
              <el-option label="硕士" value="硕士"></el-option>
              <el-option label="无" value="无"></el-option>
            </el-select>
            <!-- <el-input
            :disabled="isView"
            style="width: 220px"
            v-model="ruleForm.highestDegree"
          ></el-input> -->
          </el-form-item>

          <el-form-item
            label="个人禁忌："
            prop="personalTaboo"
            style="position: relative"
            class="form-class2 first-label"
          >
            <el-radio-group
              class="form-input"
              :disabled="isView"
              v-model="ruleForm.personalTaboo"
              @input="ruleForm.personalTabooDetail = ''"
            >
              <el-radio
                v-for="(item, index) in isNoArray"
                :label="item.id"
                :key="index"
                >{{ item.name }}</el-radio
              >
            </el-radio-group>
          </el-form-item>
          <el-form-item
            label="禁忌详情："
            prop="personalTabooDetail"
            style="position: relative"
            class="form-class2 first-label"
            v-if="ruleForm.personalTaboo === 2"
          >
            <el-input
              v-if="ruleForm.personalTaboo === 2"
              :disabled="isView"
              class="form-input"
              v-model="ruleForm.personalTabooDetail"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="政治面貌："
            prop="political"
            style="position: relative"
            class="form-class2 first-label"
          >
            <el-select
              style="width: 200px"
              v-model="ruleForm.political"
              placeholder="请选择"
              :disabled="isView"
            >
              <el-option label="群众" value="群众"></el-option>
              <el-option label="党员" value="党员"></el-option>
              <el-option label="预备党员" value="预备党员"></el-option>
              <el-option label="共青团员" value="共青团员"></el-option>
              <el-option label="其他" value="其他"></el-option>
            </el-select>
            <!-- <el-input
            :disabled="isView"
            style="width: 220px"
            v-model="ruleForm.political"
          ></el-input> -->
          </el-form-item>

          <el-form-item
            label="加入时间："
            prop="joinTime"
            style="position: relative"
            class="form-class2 first-label"
          >
            <el-date-picker
              ref="datePicker"
              :editable="false"
              style="width: 200px"
              v-model="ruleForm.joinTime"
              type="month"
              placeholder="选择月"
              value-format="yyyy-MM"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item
            label="婚姻状况："
            prop="maritalStatus"
            style="position: relative"
            class="form-class2 first-label"
          >
            <el-radio-group
              style="width: 360px"
              :disabled="isView"
              v-model="ruleForm.maritalStatus"
              @input="ruleForm.otherMarital = ''"
            >
              <el-radio
                v-for="(item, index) in marryArray"
                :label="item.id"
                :key="index"
                >{{ item.name }}</el-radio
              >
            </el-radio-group>
            <el-input
              v-if="ruleForm.maritalStatus === 4"
              :disabled="isView"
              style="width: 170px; position: absolute; top: 0; right: -220px"
              v-model="ruleForm.otherMarital"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="居住地址："
            prop="address"
            style="position: relative"
            class="form-class2 first-label"
          >
            <el-input
              :disabled="isView"
              style="width: 200px"
              v-model="ruleForm.address"
            ></el-input>
          </el-form-item>

          <el-form-item
            label="通勤时长："
            prop="commuteHours"
            style="position: relative"
            class="form-class2 first-label"
          >
            <el-input
              :disabled="isView"
              style="width: 200px"
              v-model="ruleForm.commuteHours"
            >
              <template slot="append">分钟</template>
            </el-input>
          </el-form-item>
          <br />
          <el-form-item
            label="能否出差："
            prop="businessTravel"
            style="position: relative"
            class="form-class2 first-label"
          >
            <el-radio-group
              style="width: 220px"
              :disabled="isView"
              v-model="ruleForm.businessTravel"
            >
              <el-radio label="能">能</el-radio>
              <el-radio label="否">否</el-radio>
            </el-radio-group>
          </el-form-item>

          <el-form-item
            label="能否加班："
            prop="workOvertime"
            style="position: relative"
            class="form-class2 first-label"
          >
            <el-radio-group
              style="width: 220px"
              :disabled="isView"
              v-model="ruleForm.workOvertime"
            >
              <el-radio label="能">能</el-radio>
              <el-radio label="否">否</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item
            label="能否接受公司调动工作地点："
            prop="relocation"
            style="position: relative"
            class="form-class2"
          >
            <el-radio-group :disabled="isView" v-model="ruleForm.relocation">
              <el-radio label="能">能</el-radio>
              <el-radio label="否">否</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item
            label="原月收入(税前,元)："
            prop="monthlyIncome"
            style="position: relative"
            class="form-class2 middle-label"
          >
            <el-input
              style="width: 200px"
              :disabled="isView"
              v-model="ruleForm.monthlyIncome"
              @input="
                ruleForm.monthlyIncome = ruleForm.monthlyIncome.replace(
                  /^\D*([0-9]+)?.*$/g,
                  '$1'
                )
              "
            ></el-input>
          </el-form-item>

          <el-form-item
            label="原年收入(税前,万元)："
            prop="annualIncome"
            style="position: relative"
            class="form-class2 middle-label"
          >
            <el-input
              style="width: 200px"
              :disabled="isView"
              v-model="ruleForm.annualIncome"
              @input="
                ruleForm.annualIncome = ruleForm.annualIncome.replace(
                  /^\D*(\d*(?:\.\d*)?).*$/g,
                  '$1'
                )
              "
            ></el-input>
          </el-form-item>
          <el-form-item
            label="原社保公积金缴纳基数："
            prop="cardinal"
            style="position: relative"
            class="form-class2 middle-label"
          >
            <el-input
              :disabled="isView"
              style="width: 200px"
              v-model="ruleForm.cardinal"
              @input="
                ruleForm.cardinal = ruleForm.cardinal.replace(
                  /^\D*([0-9]+)?.*$/g,
                  '$1'
                )
              "
            ></el-input>
          </el-form-item>
          <el-form-item
            label="期望薪资(税前,元)："
            prop="expectSalary"
            style="position: relative"
            class="form-class2 middle-label"
          >
            <el-input
              :disabled="isView"
              style="width: 200px"
              v-model="ruleForm.expectSalary"
              @input="
                ruleForm.expectSalary = ruleForm.expectSalary.replace(
                  /^\D*([0-9]+)?.*$/g,
                  '$1'
                )
              "
            ></el-input>
          </el-form-item>
          <el-form-item
            label="现与其它单位是否存在劳动关系?"
            prop="laborRelation"
            style="position: relative"
            label-width="250px"
            class="form-class2"
          >
            <el-radio-group
              style="width: 120px"
              :disabled="isView"
              v-model="ruleForm.laborRelation"
              @input="ruleForm.companyName = ''"
            >
              <el-radio :label="1">否</el-radio>
              <el-radio :label="2">是</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item
            label="单位名称："
            prop="companyName"
            style="position: relative"
            class="form-class2"
            v-if="ruleForm.laborRelation === 2"
          >
            <el-input
              :disabled="isView"
              style="width: 200px"
              v-model="ruleForm.companyName"
            ></el-input>
          </el-form-item>
          <br />
          <el-form-item
            label="是否曾涉及民、刑事诉讼案件或其他相关法律案件？"
            prop="legalCase"
            label-width="350px"
            style="position: relative"
          >
            <el-radio-group
              style="width: 150px"
              :disabled="isView"
              v-model="ruleForm.legalCase"
              @input="ruleForm.caseDetail = ''"
            >
              <el-radio :label="1">否</el-radio>
              <el-radio :label="2">是</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item
            label="具体事件："
            prop="caseDetail"
            style="position: relative"
            class="form-class2"
            v-if="ruleForm.legalCase === 2"
          >
            <el-input
              :disabled="isView"
              style="width: 220px"
              v-model="ruleForm.caseDetail"
            ></el-input>
          </el-form-item>
          <br />
          <el-form-item
            label="有无职业病、精神疾病、慢性病 、遗传病或其他重大病史？"
            prop="medicalHistory"
            style="position: relative"
            class="form-class2"
          >
            <el-radio-group
              style="width: 110px"
              :disabled="isView"
              v-model="ruleForm.medicalHistory"
              @input="ruleForm.medicalHistoryDetail = ''"
            >
              <el-radio :label="1">无</el-radio>
              <el-radio :label="2">有</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item
            label="详细病史："
            prop="medicalHistoryDetail"
            style="position: relative"
            class="form-class2"
            v-if="ruleForm.medicalHistory === 2"
          >
            <el-input
              :disabled="isView"
              style="width: 220px"
              v-model="ruleForm.medicalHistoryDetail"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="有无亲属在本公司？"
            label-width="170px"
            prop="hasKnowPeople"
            style="position: relative"
            class="form-class2"
          >
            <el-radio-group
              style="width: 220px"
              :disabled="isView"
              v-model="ruleForm.hasKnowPeople"
              @input="knowObject = { name: '', part: '', relation: '' }"
            >
              <el-radio :label="1">无</el-radio>
              <el-radio :label="2">有</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-card class="box-card" v-if="ruleForm.hasKnowPeople === 2">
            <el-form-item
              prop=""
              style="position: relative"
              class="form-class2 middle-label"
              v-if="ruleForm.hasKnowPeople === 2"
            >
              <el-input
                :disabled="isView"
                style="width: 220px"
                v-model="knowObject.name"
                prefix-icon="el-icon-user"
                placeholder="请输入姓名"
              ></el-input>
            </el-form-item>
            <br />
            <el-form-item
              prop=""
              style="position: relative"
              class="form-class2 middle-label"
              v-if="ruleForm.hasKnowPeople === 2"
            >
              <el-input
                :disabled="isView"
                style="width: 220px"
                v-model="knowObject.part"
                placeholder="请输入部门"
                prefix-icon="el-icon-office-building"
              ></el-input>
            </el-form-item>
            <el-form-item
              prop=""
              style="position: relative"
              class="form-class2 middle-label"
              v-if="ruleForm.hasKnowPeople === 2"
            >
              <el-input
                :disabled="isView"
                style="width: 220px"
                v-model="knowObject.relation"
                prefix-icon="el-icon-s-home"
                placeholder="请输入与本人关系"
              ></el-input>
            </el-form-item>
          </el-card>
         

        <el-form-item
            label="个人擅长工作模块："
            prop="adeptWork"
            style="position: relative"
            class="form-class2 middle-label text-area-class"
          >
            <el-input
              type="textarea"
              :autosize="{ minRows: 2, maxRows: 4 }"
              :disabled="isView"
              style="width: 100%; word-break: break-all"
              v-model="ruleForm.adeptWork"
            ></el-input>
          </el-form-item>


       <el-form-item
            label="个人核心/亮眼业绩："
            prop="personalAchievement"
            style="position: relative"
            class="form-class2 middle-label text-area-class"
          >
            <el-input
              type="textarea"
              :autosize="{ minRows: 2, maxRows: 8 }"
              :disabled="isView"
              style="width: 100%; word-break: break-all"
              v-model="ruleForm.personalAchievement"
            ></el-input>
          </el-form-item>


        <el-form-item
            label="个人希望进阶/补充工作模块："
            prop="desireImprovingSkills"
            style="position: relative"
            class="form-class2 middle-label text-area-class"
          >
            <el-input
              type="textarea"
              :autosize="{ minRows: 2, maxRows: 4 }"
              :disabled="isView"
              style="width: 100%; word-break: break-all"
              v-model="ruleForm.desireImprovingSkills"
            ></el-input>
          </el-form-item>

        <el-form-item
            label="新一年的职业目标："
            prop="careerObjective"
            style="position: relative"
            class="form-class2 middle-label text-area-class"
          >
            <el-input
              type="textarea"
              :autosize="{ minRows: 2, maxRows: 4 }"
              :disabled="isView"
              style="width: 100%; word-break: break-all"
              v-model="ruleForm.careerObjective"
            ></el-input>
          </el-form-item>


          <el-form-item
            label="个人优点："
            prop="advantage"
            style="position: relative"
            class="form-class2 middle-label text-area-class"
          >
            <el-input
              type="textarea"
              :autosize="{ minRows: 2, maxRows: 4 }"
              :disabled="isView"
              style="width: 100%; word-break: break-all"
              v-model="ruleForm.advantage"
            ></el-input>
          </el-form-item>

          
          
          <el-form-item
            label="个人缺点："
            prop="shortcoming"
            style="position: relative"
            class="form-class2 middle-label text-area-class"
          >
            <el-input
              type="textarea"
              :autosize="{ minRows: 2, maxRows: 4 }"
              :disabled="isView"
              style="width: 100%; word-break: break-all"
              v-model="ruleForm.shortcoming"
            ></el-input>
          </el-form-item>

          

          <el-form-item
            label="个人特长、技能与爱好："
            prop="hobby"
            style="position: relative"
            class="form-class2 middle-label text-area-class"
          >
            <el-input
              type="textarea"
              :autosize="{ minRows: 2, maxRows: 4 }"
              :disabled="isView"
              style="width: 100%; word-break: break-all"
              v-model="ruleForm.hobby"
            ></el-input>
          </el-form-item>

          <el-form-item
            label="获得过何种奖励或荣誉："
            prop="honor"
            style="position: relative"
            class="form-class2 middle-label text-area-class"
          >
            <el-input
              type="textarea"
              :autosize="{ minRows: 2, maxRows: 4 }"
              :disabled="isView"
              style="width: 100%; word-break: break-all"
              v-model="ruleForm.honor"
            ></el-input>
          </el-form-item>

          <div style="font-weight: bold; margin-bottom: 10px">
            <span style="color: #f56c6c">*</span>学习经历
            （请先填写最高学历相关信息）
          </div>
          <div class="table-container">
            <div
              class="td-for-item-mobile"
              v-for="(item4, index4) in educateArray"
              :key="index4 + 'a'"
            >
              <div class="table-line">
                <div class="td-class">开始时间</div>
                <div style="width: 60%" class="td-class">
                  <el-date-picker
                    :editable="false"
                    style="width: 100%"
                    size="mini"
                    v-model="item4.timeArray[0]"
                    type="month"
                    placeholder="选择月"
                    value-format="yyyy-MM"
                    @change="
                      (value) => {
                        validateDate(value, item4.timeArray[1]);
                      }
                    "
                  >
                  </el-date-picker>
                </div>
              </div>

              <div class="table-line">
                <div class="td-class">结束时间</div>
                <div style="width: 60%" class="td-class">
                  <el-date-picker
                    style="width: 100%"
                    :editable="false"
                    v-model="item4.timeArray[1]"
                    type="month"
                    size="mini"
                    placeholder="选择月"
                    value-format="yyyy-MM"
                    @change="
                      (value) => {
                        validateDate(item4.timeArray[0], value);
                      }
                    "
                  >
                  </el-date-picker>
                </div>
              </div>

              <div class="table-line">
                <div class="td-class">学校名称</div>
                <div style="width: 60%" class="td-class">
                  <el-input
                    size="mini"
                    :disabled="isView"
                    v-model="item4.schoolName"
                  ></el-input>
                </div>
              </div>
              <div class="table-line">
                <div class="td-class">所学专业</div>
                <div style="width: 60%" class="td-class">
                  <el-input
                    size="mini"
                    :disabled="isView"
                    v-model="item4.major"
                  ></el-input>
                </div>
              </div>
              <div class="table-line">
                <div class="td-class">毕(结肄)业</div>
                <div style="width: 60%" class="td-class">
                  <el-select
                    size="mini"
                    style="width: 100%"
                    v-model="item4.status"
                    placeholder="请选择"
                    :disabled="isView"
                  >
                    <el-option label="毕业" value="毕业"></el-option>
                    <el-option label="结业" value="结业"></el-option>
                    <el-option label="肄业" value="肄业"></el-option>
                  </el-select>
                </div>
              </div>
            </div>
          </div>

          <div style="font-weight: bold; margin-bottom: 10px; margin-top: 10px">
            <span style="color: #f56c6c">*</span>工作经历 （请先填写最近的经历）
          </div>
          <div class="table-container">
            <div
              class="td-for-item-mobile"
              v-for="(item5, index5) in workArray"
              :key="index5 + 'b'"
            >
              <div class="table-line">
                <div class="td-class">开始时间</div>
                <div style="width: 60%" class="td-class">
                  <el-date-picker
                    :editable="false"
                    size="mini"
                    style="width: 100%"
                    v-model="item5.timeArray[0]"
                    type="month"
                    placeholder="选择月"
                    value-format="yyyy-MM"
                    @change="
                      (value) => {
                        validateDate(value, item5.timeArray[1]);
                      }
                    "
                  />
                </div>
              </div>
              <div class="table-line">
                <div class="td-class">结束时间</div>
                <div style="width: 60%" class="td-class">
                  <el-date-picker
                    :editable="false"
                    size="mini"
                    style="width: 100%"
                    v-model="item5.timeArray[1]"
                    type="month"
                    placeholder="选择月"
                    value-format="yyyy-MM"
                    @change="
                      (value) => {
                        validateDate(item5.timeArray[0], value);
                      }
                    "
                  >
                  </el-date-picker>
                </div>
              </div>

              <div class="table-line">
                <div class="td-class">工作单位</div>
                <div style="width: 60%" class="td-class">
                  <el-input
                    size="mini"
                    :disabled="isView"
                    v-model="item5.companyName"
                  ></el-input>
                </div>
              </div>


              <div class="table-line">
                <div class="td-class">行业</div>
                <div style="width: 60%" class="td-class">
                  <el-input
                    size="mini"
                    :disabled="isView"
                    v-model="item5.careerSector"
                  ></el-input>
                </div>
              </div>

              <div class="table-line">
                <div class="td-class">规模（人数、营收）</div>
                <div style="width: 60%" class="td-class">
                  <el-input
                    size="mini"
                    :disabled="isView"
                    v-model="item5.companyScale"
                  ></el-input>
                </div>
              </div>

              <div class="table-line">
                <div class="td-class">职务</div>
                <div style="width: 60%" class="td-class">
                  <el-input
                    size="mini"
                    :disabled="isView"
                    v-model="item5.post"
                  ></el-input>
                </div>
              </div>
              <div class="table-line">
                <div class="td-class">所在城市</div>
                <div style="width: 60%" class="td-class">
                  <el-input
                    size="mini"
                    :disabled="isView"
                    v-model="item5.city"
                  ></el-input>
                </div>
              </div>
              <div class="table-line">
                <div class="td-class">离职薪资</div>
                <div style="width: 60%" class="td-class">
                  <el-input
                    size="mini"
                    :disabled="isView"
                    v-model="item5.lastSalary"
                  ></el-input>
                </div>
              </div>
              <div class="table-line">
                <div class="td-class">离职原因</div>
                <div style="width: 60%" class="td-class">
                  <el-input
                    size="mini"
                    :disabled="isView"
                    v-model="item5.reason"
                  ></el-input>
                </div>
              </div>
            </div>
          </div>

          <div style="font-weight: bold; margin-bottom: 10px; margin-top: 10px">
            <span style="color: #f56c6c">*</span>社会关系（请填写父母、配偶）
          </div>
          <div class="table-container">
            <div
              class="td-for-item-mobile"
              v-for="(item6, index6) in relationArray"
              :key="index6 + 'c'"
            >
              <div class="table-line">
                <div class="td-class">姓名</div>
                <div style="width: 60%" class="td-class">
                  <el-input
                    size="mini"
                    :disabled="isView"
                    style="width: 100%"
                    v-model="item6.name"
                  ></el-input>
                </div>
              </div>

              <div class="table-line">
                <div class="td-class">年龄</div>
                <div style="width: 60%" class="td-class">
                  <el-input
                    size="mini"
                    :disabled="isView"
                    style="width: 100%"
                    v-model="item6.age"
                    @input="
                      item6.age = item6.age.replace(
                        /^\D*(\d*(?:\.\d*)?).*$/g,
                        '$1'
                      )
                    "
                  ></el-input>
                </div>
              </div>

              <div class="table-line">
                <div class="td-class">与本人关系</div>
                <div style="width: 60%" class="td-class">
                  <el-input
                    size="mini"
                    :disabled="isView"
                    style="width: 100%"
                    v-model="item6.relation"
                  ></el-input>
                </div>
              </div>
              <div class="table-line">
                <div class="td-class">工作单位及职务</div>
                <div style="width: 60%" class="td-class">
                  <el-input
                    size="mini"
                    :disabled="isView"
                    style="width: 100%"
                    v-model="item6.companyNamePost"
                  ></el-input>
                </div>
              </div>

              <div class="table-line">
                <div class="td-class">联系方式</div>
                <div style="width: 60%" class="td-class">
                  <el-input
                    size="mini"
                    :disabled="isView"
                    style="width: 100%"
                    v-model="item6.contact"
                  ></el-input>
                </div>
              </div>
            </div>
          </div>

          <el-form-item
            label="了解到本次招聘信息的途径："
            prop="knowWay"
            style="position: relative"
            class="form-class2 long-label"
          >
            <el-input
              :disabled="isView"
              style="width: 300px"
              v-model="ruleForm.knowWay"
            ></el-input>
          </el-form-item>
          <br />
          <el-form-item
            label="预计到职日期："
            prop="checkDate"
            style="position: relative"
            class="form-class2 long-label"
          >
            <!-- <el-input
            :disabled="isView"
            style="width: 300px"
            v-model="ruleForm.checkDate"
          ></el-input> -->
            <el-date-picker
              :editable="false"
              style="width: 300px"
              v-model="ruleForm.checkDate"
              type="date"
              placeholder="选择日期"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </el-form-item>
          <br />
        </el-form>
        <div style="font-weight: bold">个人声明</div>
        <div style="margin-top: 10px">
          1、本人（<el-radio-group :disabled="isView" v-model="ruleForm.agree">
            <el-radio :label="1">同意</el-radio>
            <el-radio :label="2">不同意</el-radio> </el-radio-group
          >）公司对以上相关信息予以调查核实；
        </div>
        <div style="margin-top: 10px">
          2、本人保证以上所填各项内容均属实。若有不实或虚构，愿承担取消录用资格或入职后被解除劳动关系等相关法律责任。
        </div>
        <div style="margin-top: 30px; text-align: right">
          签名：<el-button v-if="!resultImg" type="primary" @click="toSign"
            >去签名</el-button
          >
          <img
            v-else
            :src="resultImg"
            style="width: 100px; height: 40px"
            @click="toSign"
          />
        </div>
        <div style="margin-top: 10px; text-align: right">
          日期：<el-date-picker
            :editable="false"
            v-model="ruleForm.signTime"
            type="date"
            placeholder="选择日期"
            value-format="yyyy-MM-dd"
          >
          </el-date-picker>
        </div>
        <div style="width: 180px; margin: 0 auto; margin-top: 20px">
          <el-button @click="returnBackInfo">重置</el-button>
          <el-button type="primary" @click="submitInfo">提交</el-button>
        </div>

        <el-dialog
          :close-on-click-modal="false"
          title="签名"
          :visible.sync="signDialog"
          center
          width="100%"
        >
          <div style="border: 1px solid #e7e7e7">
            <vue-esign
              ref="esign"
              :isCrop="isCrop"
              :lineWidth="lineWidth"
              :lineColor="lineColor"
              :bgColor.sync="bgColor"
            />
          </div>
          <span slot="footer" class="dialog-footer">
            <el-button @click="handleReset">重置</el-button>
            <el-button type="primary" @click="handleGenerate">确定</el-button>
          </span>
        </el-dialog>
      </div>
    </el-main>
    <el-footer> 中地行集团 </el-footer>
  </el-container>
</template>
<script>
import exam from "@/api/exam";
import vueEsign from "vue-esign";

export default {
  name: "mCvForm",
  components: { vueEsign },
  data() {
    const checkPhone = (rule, value, callback) => {
      if (!/^1(2|3|4|5|6|7|8|9)\d{9}$/.test(value)) {
        return callback("请输入正确的手机号");
      }
      callback();
    };
    const checkId = (rule, value, callback) => {
      if (
        !/^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/.test(
          value
        )
      ) {
        return callback("请输入格式正确的身份证号");
      }
      callback();
    };
    return {
      activeNames: ["1"],
      labelPosition: "left",
      userInfoId: "",
      selectRow: "",
      knowObject: {
        name: "",
        part: "",
        relation: "",
      },
      lineWidth: 6,
      lineColor: "#000000",
      bgColor: "",
      resultImg: "",
      isCrop: false,
      signDialog: false,
      educateArray: [
        {
          schoolName: "",
          startTime: "",
          endTime: "",
          timeArray: ["", ""],
          major: "",
          status: "",
        },
        {
          schoolName: "",
          startTime: "",
          endTime: "",
          timeArray: ["", ""],
          major: "",
          status: "",
        },
        {
          schoolName: "",
          startTime: "",
          endTime: "",
          timeArray: ["", ""],
          major: "",
          status: "",
        },
      ],
      workArray: [
        {
          companyName: "",
          startTime: "",
          endTime: "",
          timeArray: ["", ""],
          city: "",
          lastSalary: "",
          careerSector: "",
          companyScale: "",
          post: "",
          reason: "",
        },
        {
          companyName: "",
          startTime: "",
          endTime: "",
          timeArray: ["", ""],
          city: "",
          lastSalary: "",
          careerSector: "",
          companyScale: "",
          post: "",
          reason: "",
        },
        {
          companyName: "",
          startTime: "",
          endTime: "",
          timeArray: ["", ""],
          city: "",
          lastSalary: "",
          careerSector: "",
          companyScale: "",
          post: "",
          reason: "",
        },
        {
          companyName: "",
          startTime: "",
          endTime: "",
          timeArray: ["", ""],
          city: "",
          lastSalary: "",
          careerSector: "",
          companyScale: "",
          post: "",
          reason: "",
        },
      ],
      relationArray: [
        {
          name: "",
          age: "",
          relation: "",
          companyNamePost: "",
          contact: "",
        },
        {
          name: "",
          age: "",
          relation: "",
          companyNamePost: "",
          contact: "",
        },
        {
          name: "",
          age: "",
          relation: "",
          companyNamePost: "",
          contact: "",
        },
      ],
      marryArray: [
        { id: 1, name: "未婚" },
        { id: 2, name: "已婚" },
        { id: 3, name: "离异" },
        { id: 4, name: "其他" },
      ],
      sexArray: [
        { id: 1, name: "男" },
        { id: 2, name: "女" },
      ],
      isNoArray: [
        { id: 1, name: "无" },
        { id: 2, name: "有" },
      ],
      isView: false,
      ruleForm: {
        caseDetail: "",
        medicalHistoryDetail: "",
        companyName: "",
        knowPeopleInfo: "",
        otherMarital: "",
        appliedPosition: "",
        postId: "",
        name: "",
        idNo: "",
        gender: "",
        nativePlace: "",
        nation: "",
        telNo: "",
        age: "",
        height: "",
        weight: "",
        highestEducation: "",
        highestDegree: "",
        personalTaboo: "",
        personalTabooDetail: "",
        political: "",
        joinTime: "",
        maritalStatus: "",
        address: "",
        commuteHours: "",
        businessTravel: "",
        workOvertime: "",
        relocation: "",
        monthlyIncome: "",
        annualIncome: "",
        cardinal: "",
        expectSalary: "",
        laborRelation: "",
        legalCase: "",
        medicalHistory: "",
        hasKnowPeople: "",
        hobby: "",
        adeptWork: "",
        personalAchievement: "",
        desireImprovingSkills: "",
        careerObjective: "",
        advantage: "",
        shortcoming: "",
        honor: "",
        learningExperienceList: [
          // {
          //   schoolName: "",
          //   startTime: "",
          //   endTime: "",
          //   major: "",
          //   status: "",
          // },
        ],
        workExperienceList: [
          // {
          //   companyName: "",
          //   startTime: "",
          //   endTime: "",
          //   city: "",
          //   post: "",
          //   reason: "",
          // },
        ],
        socialRelationList: [
          // {
          //   name: "",
          //   age: "",
          //   relation: "",
          //   companyNamePost: "",
          //   contact: "",
          // },
        ],
        knowWay: "",
        checkDate: "",
        agree: "",
        signTime: "",
        signName: "",
      },
      rules: {
        postId: [
          { required: true, message: "请选择应聘岗位", trigger: "change" },
        ],
        name: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        idNo: [
          { required: true, message: "请输入身份证号码", trigger: "blur" },
          { validator: checkId, trigger: "blur" },
        ],
        gender: [{ required: true, message: "请选择性别", trigger: "change" }],
        nativePlace: [
          { required: true, message: "请输入籍贯", trigger: "blur" },
        ],
        nation: [{ required: true, message: "请输入民族", trigger: "blur" }],
        telNo: [
          { required: true, message: "请输入电话", trigger: "blur" },
          { validator: checkPhone, trigger: "blur" },
        ],
        age: [{ required: true, message: "请输入年龄", trigger: "blur" }],
        height: [{ required: true, message: "请输入身高", trigger: "blur" }],
        weight: [{ required: true, message: "请输入体重", trigger: "blur" }],
        highestEducation: [
          { required: true, message: "请输入最高学历", trigger: "blur" },
        ],
        highestDegree: [
          { required: true, message: "请输入最高学位", trigger: "blur" },
        ],
        personalTaboo: [
          { required: true, message: "请选择个人禁忌", trigger: "change" },
        ],
        political: [
          { required: true, message: "请输入政治面貌", trigger: "blur" },
        ],
        joinTime: [
          { required: false, message: "请选择加入时间", trigger: "change" },
        ],
        maritalStatus: [
          { required: true, message: "请选择婚姻状况", trigger: "change" },
        ],
        address: [
          { required: true, message: "请输入居住地址", trigger: "blur" },
        ],
        commuteHours: [
          { required: true, message: "请输入通勤时长", trigger: "blur" },
        ],
        businessTravel: [
          { required: true, message: "请选择能否出差", trigger: "change" },
        ],
        workOvertime: [
          { required: true, message: "请选择能否加班", trigger: "change" },
        ],
        relocation: [
          {
            required: true,
            message: "请选择能否接受公司调动工作地点",
            trigger: "change",
          },
        ],
        monthlyIncome: [
          { required: true, message: "请输入原月收入", trigger: "blur" },
        ],
        annualIncome: [
          { required: true, message: "请输入原年收入", trigger: "blur" },
        ],
        cardinal: [
          {
            required: true,
            message: "请输入原社保公积金缴纳基数",
            trigger: "blur",
          },
        ],
        expectSalary: [
          { required: true, message: "请输入期望薪资", trigger: "blur" },
        ],
        laborRelation: [
          {
            required: true,
            message: "请选择是否存在劳动关系",
            trigger: "change",
          },
        ],
        legalCase: [
          {
            required: true,
            message: "请选择是否曾涉及民、刑事诉讼案件或其他相关法律案件",
            trigger: "change",
          },
        ],
        medicalHistory: [
          {
            required: true,
            message:
              "请选择有无职业病、精神疾病、慢性病 、遗传病或其他重大病史",
            trigger: "change",
          },
        ],
        hasKnowPeople: [
          {
            required: true,
            message: "请选择有无亲属在本公司",
            trigger: "change",
          },
        ],
        checkDate: [
          {
            required: true,
            message: "请选择预计到职日期",
            trigger: "change",
          },
        ],
        hobby: [
          {
            required: true,
            message: "请输入个人特长、技能与爱好",
            trigger: "blur",
          },
        ],

         adeptWork: [
          {
            required: true,
            message: "请输入个人擅长工作模块",
            trigger: "blur",
          },
        ],
        personalAchievement: [
          {
            required: true,
            message: "请输入个人核心",
            trigger: "blur",
          },
        ],
        desireImprovingSkills: [
          {
            required: true,
            message: "请输入个人希望进阶内容",
            trigger: "blur",
          },
        ],
        careerObjective: [
          {
            required: true,
            message: "请输入新一年的职业目标",
            trigger: "blur",
          },
        ],
        advantage: [
          { required: true, message: "请输入个人优点", trigger: "blur" },
        ],
        shortcoming: [
          { required: true, message: "请输入个人缺点", trigger: "blur" },
        ],
      },
      userInfoDialog: false,

      queryInfo: {
        examType: null,
        startTime: null,
        endTime: null,
        examName: null,
        pageNo: 0,
        pageSize: 10,
      },
      //表格是否在加载
      loading: true,
      //考试类型信息
      examType: [
        {
          info: "公开考试",
          type: 1,
        },
        {
          info: "需要密码",
          type: 2,
        },
      ],
      //考试信息
      examInfo: [],
      //查询到的考试总数
      total: 0,
      //开始考试的提示框
      startExamDialog: false,
      //当前选中的考试的信息
      currentSelectedExam: {},
      //可面试的职位列表
      posts: [],
      //职位对应的考试ID
      examId: null,
    };
  },
  created() {
    this.getExamInfo();
    this.getPosts();
  },
  methods: {
    toLoginPage() {
      localStorage.removeItem("authorization");
      this.$router.push("/");
    },
    changePost(postId) {
      let index = this.posts.findIndex((item) => item.postId === postId);
      if (index > -1) {
        this.ruleForm.appliedPosition = this.posts[index].postName;
        console.log("ruleForm", this.ruleForm);
      }
    },
    getPosts() {
      exam.getPendingPosts().then((res) => {
        if (res.code === 200) {
          this.posts = res.data;
        }
      });
    },
    autoGetAge() {
      // console.log()
      if (
        this.ruleForm.idNo &&
        /^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/.test(
          this.ruleForm.idNo
        )
      ) {
        console.log(
          new Date().getFullYear(),
          this.ruleForm.idNo.substring(6, 11)
        );
        this.ruleForm.age =
          new Date().getFullYear() -
          Number(this.ruleForm.idNo.substring(6, 10));
      }
    },
    validateDate(v1, v2) {
      console.log("v1", v1, v2);
      if (v1 && v2) {
        let date1 = new Date(v1).getTime();
        let date2 = new Date(v2).getTime();
        console.log(date1, date2);
        if (date1 > date2) {
          this.$message.warning("起始时间应小于结束时间");
        }
      }
    },
    returnBackInfo() {
      this.userInfoDialog = false;
      this.knowObject = {
        name: "",
        part: "",
        relation: "",
      };
      this.educateArray = [
        {
          schoolName: "",
          startTime: "",
          endTime: "",
          timeArray: ["", ""],
          major: "",
          status: "",
        },
        {
          schoolName: "",
          startTime: "",
          endTime: "",
          timeArray: ["", ""],
          major: "",
          status: "",
        },
        {
          schoolName: "",
          startTime: "",
          endTime: "",
          timeArray: ["", ""],
          major: "",
          status: "",
        },
      ];
      this.workArray = [
        {
          companyName: "",
          startTime: "",
          endTime: "",
          timeArray: ["", ""],
          city: "",
          lastSalary: "",
          careerSector: "",
          companyScale: "",
          post: "",
          reason: "",
        },
        {
          companyName: "",
          startTime: "",
          endTime: "",
          timeArray: ["", ""],
          city: "",
          lastSalary: "",
          careerSector: "",
          companyScale: "",
          post: "",
          reason: "",
        },
        {
          companyName: "",
          startTime: "",
          endTime: "",
          timeArray: ["", ""],
          city: "",
          lastSalary: "",
          careerSector: "",
          companyScale: "",
          post: "",
          reason: "",
        },
        {
          companyName: "",
          startTime: "",
          endTime: "",
          timeArray: ["", ""],
          city: "",
          lastSalary: "",
          careerSector: "",
          companyScale: "",
          post: "",
          reason: "",
        },
      ];
      this.relationArray = [
        {
          name: "",
          age: "",
          relation: "",
          companyNamePost: "",
          contact: "",
        },
        {
          name: "",
          age: "",
          relation: "",
          companyNamePost: "",
          contact: "",
        },
        {
          name: "",
          age: "",
          relation: "",
          companyNamePost: "",
          contact: "",
        },
      ];
      this.ruleForm = {
        caseDetail: "",
        medicalHistoryDetail: "",
        companyName: "",
        knowPeopleInfo: "",
        otherMarital: "",
        appliedPosition: "",
        postId: "",
        name: "",
        idNo: "",
        gender: "",
        nativePlace: "",
        nation: "",
        telNo: "",
        age: "",
        height: "",
        weight: "",
        highestEducation: "",
        highestDegree: "",
        personalTaboo: "",
        personalTabooDetail: "",
        political: "",
        joinTime: "",
        maritalStatus: "",
        address: "",
        commuteHours: "",
        businessTravel: "",
        workOvertime: "",
        relocation: "",
        monthlyIncome: "",
        annualIncome: "",
        cardinal: "",
        expectSalary: "",
        laborRelation: "",
        legalCase: "",
        medicalHistory: "",
        hasKnowPeople: "",
        hobby: "",
        adeptWork: "",
        personalAchievement: "",
        desireImprovingSkills: "",
        careerObjective: "",
        advantage: "",
        shortcoming: "",
        honor: "",
        learningExperienceList: [
          // {
          //   schoolName: "",
          //   startTime: "",
          //   endTime: "",
          //   major: "",
          //   status: "",
          // },
        ],
        workExperienceList: [
          // {
          //   companyName: "",
          //   startTime: "",
          //   endTime: "",
          //   city: "",
          //   post: "",
          //   reason: "",
          // },
        ],
        socialRelationList: [
          // {
          //   name: "",
          //   age: "",
          //   relation: "",
          //   companyNamePost: "",
          //   contact: "",
          // },
        ],
        knowWay: "",
        checkDate: "",
        agree: "",
        signTime: "",
        signName: "",
      };
    },
    submitInfo() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          if (this.resultImg === "") {
            this.$message.warning("请输入签名");
            return;
          }
          if (this.ruleForm.agree === "") {
            this.$message.warning("请选择是否同意");
            return;
          }
          let tempData = JSON.parse(JSON.stringify(this.ruleForm));
          const formData = new FormData();
          this.base64ImgtoFile(this.resultImg);
          formData.append("file", this.base64ImgtoFile(this.resultImg));
          exam.uploadFile(formData).then((resp) => {
            if (resp.code === 200) {
              console.log("resp", resp);
              this.educateArray.forEach((item) => {
                tempData.learningExperienceList.push({
                  schoolName: item.schoolName,
                  startTime:
                    item.timeArray && item.timeArray.length > 0
                      ? item.timeArray[0]
                      : "",
                  endTime:
                    item.timeArray && item.timeArray.length > 0
                      ? item.timeArray[1]
                      : "",
                  major: item.major,
                  status: item.status,
                });
              });
              this.workArray.forEach((item) => {
                tempData.workExperienceList.push({
                  companyName: item.companyName,
                  startTime:
                    item.timeArray && item.timeArray.length > 0
                      ? item.timeArray[0]
                      : "",
                  endTime:
                    item.timeArray && item.timeArray.length > 0
                      ? item.timeArray[1]
                      : "",
                  city: item.city,
                  post: item.post,
                  lastSalary:item.lastSalary,
                  reason: item.reason,
                  careerSector: item.careerSector,
                  companyScale: item.companyScale,
                });
              });
              this.relationArray.forEach((item) => {
                tempData.socialRelationList.push({
                  name: item.name,
                  age: item.age,
                  relation: item.relation,
                  companyNamePost: item.companyNamePost,
                  contact: item.contact,
                });
              });
              tempData.signName = resp.data.key;
              if (this.ruleForm.hasKnowPeople === 2) {
                tempData.knowPeopleInfo =
                  this.knowObject.name +
                  "," +
                  this.knowObject.part +
                  "," +
                  this.knowObject.relation;
              } else {
                tempData.knowPeopleInfo = "";
              }
              console.log("tempData", tempData);

              exam.saveUserInfo(tempData).then((resInfo) => {
                if (resInfo.code === 200) {
                  this.$message.info("保存成功");
                  this.userInfoId = resInfo.data;
                  this.gotoExam();
                  // this.returnBackInfo();

                  // if (this.selectRow.type === 2) {
                  //   this.$prompt("请提供考试密码", "Tips", {
                  //     confirmButtonText: "确定",
                  //     cancelButtonText: "取消",
                  //   })
                  //     .then(({ value }) => {
                  //       if (value === this.selectRow.password) {
                  //         this.startExamDialog = true;
                  //         this.currentSelectedExam = this.selectRow;
                  //       } else {
                  //         this.$message.error("密码错误o(╥﹏╥)o");
                  //       }
                  //     })
                  //     .catch(() => {});
                  // } else {
                  //   this.startExamDialog = true;
                  //   this.currentSelectedExam = this.selectRow;
                  // }
                } else {
                  this.$message.error(resInfo.message);
                }
              });
            }
          });
        }
      });
    },
    gotoExam() {
      exam.getExamIdByPostId(this.ruleForm.postId).then((res) => {
        if (res.code === 200) {
          this.examId = res.data;
          this.$router.push(`/exam/${this.examId}/${this.userInfoId}`);
        } else {
          this.$message.error(res.message);
        }
      });
    },
    base64ImgtoFile(dataurl, filename = "file") {
      let arr = dataurl.split(",");
      let mime = arr[0].match(/:(.*?);/)[1];
      let suffix = mime.split("/")[1];
      let bstr = atob(arr[1]);
      let n = bstr.length;
      let u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], `${filename}.${suffix}`, {
        type: mime,
      });
    },
    toSign() {
      // this.userInfoDialog = false;
      this.signDialog = true;
    },
    handleReset() {
      this.$refs.esign.reset();
    },
    handleGenerate() {
      this.$refs.esign
        .generate()
        .then((res) => {
          this.resultImg = res;
          console.log("this.resultImg", this.resultImg);
          this.signDialog = false;
        })
        .catch((err) => {
          this.$message.warning("签名不能为空");
          alert(err); // 画布没有签字时会执行这里 'Not Signned'
        });
    },
    //考试类型搜索
    typeChange(val) {
      this.queryInfo.examType = val;
      this.getExamInfo();
    },
    //查询考试信息
    getExamInfo() {
      exam.getExamInfo(this.queryInfo).then((resp) => {
        if (resp.code === 200) {
          resp.data.data.forEach((item) => {
            item.startTime = String(item.startTime).substring(0, 10);
            item.endTime = String(item.endTime).substring(0, 10);
          });
          this.examInfo = resp.data.data;
          this.total = resp.data.total;
          this.loading = false;
        }
      });
    },
    //分页页面大小改变
    handleSizeChange(val) {
      this.queryInfo.pageSize = val;
      this.getExamInfo();
    },
    //分页插件的页数
    handleCurrentChange(val) {
      this.queryInfo.pageNo = val;
      this.getExamInfo();
    },
    //去考试准备页面
    toStartExam(row) {
      // exam.getUserInfo(formData).then((resp) => {
      //   if (resp.code === 200) {
      //   }
      // });
      this.returnBackInfo();
      this.userInfoDialog = true;
      this.selectRow = row;
    },
  },
  computed: {
    //检查考试的合法性
    checkExam(row) {
      return (row) => {
        let date = new Date();
        if (row.status === 2) return false;
        if (row.startTime === "null" && row.endTime === "null") {
          return true;
        } else if (row.startTime === "null") {
          return date < new Date(row.endTime);
        } else if (row.endTime === "null") {
          return date > new Date(row.startTime);
        } else if (
          date > new Date(row.startTime) &&
          date < new Date(row.endTime)
        )
          return true;
      };
    },
  },
};
</script>
<style scoped>
html,
body {
  overflow: hidden;
  height: 100%;
}

.return-button {
  position: absolute;
  right: 2%;
  top: 20px;
}
.el-container {
  display: block;
}
.form-class2 {
  margin-right: 5px;
}
.first-label >>> .el-form-item__label {
  width: 95px !important;
  text-align: right;
}
.top-label >>> .el-form-item__label {
  width: 350px !important;
  text-align: center;
  display: block !important;
}

.form-input {
  width: 200px;
}
.top-top-label >>> .el-form-item__label {
  width: 395px !important;
  text-align: left;
  display: block !important;
}
.long-label >>> .el-form-item__label {
  width: 230px !important;
  /* text-align: left;
  display: block !important; */
}
/* .long-label >>> .el-form-item__label label {
  display: block;
}
.top-label >>> .el-form-item__label label {
  display: block;
}
.top-top-label >>> .el-form-item__label label {
  display: block;
} */
.middle-label >>> .el-form-item__label {
  width: 180px !important;
  display: block;
  /* text-align: left; */
}
.text-area-class >>> .el-form-item__content {
  width: 400px !important;
}
</style>
<style scoped lang="scss">
.main-container {
  padding-left: 0;
  padding-right: 0;
}
.table-container {
  padding: 5px;
  border: 1px solid #dcdfe6;
  margin-bottom: 10px;
  overflow: hidden;
  font-size: 14px;
  .table-header {
  }
  .table-line {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
  }
  .td-class {
    text-align: center;
    float: left;
  }
}
.td-for-item-mobile {
  border-bottom: 1px solid #dcdfe6;
  margin-top: 10px;
}
.td-for-item-mobile:last-child {
  border-bottom: none;
}
</style>
